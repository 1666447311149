import React, { memo } from 'react';
import OnboardingModalContentContainer from '../OnboardingModal/ModalContent/OnboardingModalContentContainer';
import { useNavbarModalContext } from './context/NavbarModalContext';

const OnboaridngModalComponent = memo(() => {
  const { ModalComponent, closeModal, isOpen } = useNavbarModalContext();

  return (
    <ModalComponent containerClassName="sm:max-h-fit">
      {isOpen && <OnboardingModalContentContainer closeModal={closeModal} />}
    </ModalComponent>
  );
});

export default OnboaridngModalComponent;
