import { TOutputData, TBlockType, TListOutputData } from './types';

const HTML_TAG_REGEXP = /<\/?[^>]+(>|$)/g;

export const bodyToPlainString = (data: TOutputData): string => data?.blocks?.map(blockToPlainString).join(' ') || '';

const blockToPlainString = (block: any) =>
  PLAIN_STRING_GETTERS[block?.type as TBlockType]?.(block?.data)?.replaceAll('&nbsp;', ' ');

const headerToPlainString = (data: { text: string }) => data.text.replaceAll(HTML_TAG_REGEXP, ' ');

const listToPlainString = (data: TListOutputData): string =>
  [data?.content, ...data.items.map(listToPlainString)].filter(Boolean).join(' ');

const paragraphToPlainString = (data: { text: string }) => data.text.replaceAll(HTML_TAG_REGEXP, ' ');

const quoteToPlainString = (data: { text: string; caption: string }) =>
  data.text.replaceAll(HTML_TAG_REGEXP, ' ') + data.caption;

const tableToPlainString = (data: { content: string[][] }) => data.content.flat().join(' ');

const codeToPlainString = (data: { code: string }) => data.code;

const imageToPlainString = (data: { caption: string }) => data.caption;

const PLAIN_STRING_GETTERS: Record<TBlockType, (data: any) => string> = {
  paragraph: paragraphToPlainString,
  code: codeToPlainString,
  header: headerToPlainString,
  list: listToPlainString,
  quote: quoteToPlainString,
  table: tableToPlainString,
  image: imageToPlainString,
};
