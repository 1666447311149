import { isLensProductionEnv } from 'config';

const getShareArticleOnLensUrl = (url: string, useTerritoryText?: boolean) => {
  const text = useTerritoryText
    ? encodeURIComponent('Found this great Territory!')
    : encodeURIComponent('Found this good read!');

  const HEY_URL = isLensProductionEnv ? 'https://hey.xyz' : 'https://testnet.hey.xyz';
  return `${HEY_URL}/?text=${text}&url=${encodeURIComponent(url)}`;
};
export default getShareArticleOnLensUrl;
