import { AnalyticsModalType } from '../../Modal/constants';
import { TModalType } from '../types';

export const getAnalyticsModalNameByNavbarModalType = (modalType: TModalType | undefined) => {
  switch (modalType) {
    case 'share':
      return AnalyticsModalType.postShare;
    case 'onboarding':
      return AnalyticsModalType.onboarding;
    case 'delete':
      return AnalyticsModalType.postDelete;
    case 'unpublish':
      return AnalyticsModalType.postUnpublish;
    case 'search':
      return AnalyticsModalType.search;
    default:
      return undefined;
  }
};
