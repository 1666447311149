import { useModal } from 'src/components/Modal/useModal';
import { ArticleEditorStatusChangeModal } from './ArticleEditorStatusChangeModal';

type TUseArticleEditorStatusChangeModalProps = {
  article: {
    id: string;
    slug: string;
  };
  error: any | null | undefined;
  loading: boolean;
  handleSubmitArticleEditor: () => void;
};

export const useArticleEditorStatusChangeModal = ({
  error,
  loading,
  handleSubmitArticleEditor,
  article,
}: TUseArticleEditorStatusChangeModalProps) => {
  // eslint-disable-next-line no-nested-ternary
  const status = loading ? 'loading' : error ? 'failure' : 'success';
  const { ModalComponent, ...modalData } = useModal({ isCloseEnabled: status === 'success' || status === 'failure' });

  return {
    ...modalData,
    ModalComponent: () => (
      <ArticleEditorStatusChangeModal
        articleId={article.id}
        articleSlug={article.slug}
        status={status}
        handleSubmitArticleEditor={handleSubmitArticleEditor}
        ModalComponent={ModalComponent}
      />
    ),
  };
};
