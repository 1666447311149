import { useEffect } from 'react';
import { OutputData } from '@editorjs/editorjs';

const AUTO_SAVE_THRESHOLD = 30000; // 30 seconds

export type TUseAutosaveChanges = {
  body: OutputData;
  title: string;
  saveChanges: () => Promise<void>;
  hasUnsavedChanges: boolean;
};

export const useAutosaveChanges = ({ saveChanges, hasUnsavedChanges, body, title }: TUseAutosaveChanges) => {
  useEffect(() => {
    const saveInterval = setInterval(() => {
      if (hasUnsavedChanges) {
        saveChanges();
      }
    }, AUTO_SAVE_THRESHOLD);
    return () => {
      clearInterval(saveInterval);
    };
  }, [hasUnsavedChanges, body, title]);
  return null;
};
