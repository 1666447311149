import React from 'react';
import { ShareArticleModalContainer } from '../ShareModal/ShareArticleModalContainer';
import { useNavbarModalContext } from './context/NavbarModalContext';

const ShareArticleModalComponent = () => {
  const { ModalComponent } = useNavbarModalContext();
  return (
    <ModalComponent>
      <ShareArticleModalContainer articleType="ARTICLE" />
    </ModalComponent>
  );
};
export default ShareArticleModalComponent;
