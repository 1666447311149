import { useEffect, useRef } from 'react';
import { NAVBAR_ID } from '../constants';

const DEFAULT_NAVBAR_HEIGHT = 72;

export const useNavbarHeight = () => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const navbarHeight = navbarRef.current?.clientHeight ?? DEFAULT_NAVBAR_HEIGHT;

  useEffect(() => {
    const navbar = document.getElementById(NAVBAR_ID);
    // @ts-ignore
    navbarRef.current = navbar;
  }, []);

  return navbarHeight;
};
