import React from 'react';
import { StatusFailureIcon } from 'src/components/Icons/StatusFailureIcon';
import { StatusChangeModalContent } from 'src/components/StatusChangeModal/StatusChangeModalContent';

export const ArticleEditorStatusChangeFailureModalContent = ({
  onRetryButtonClick,
}: {
  onRetryButtonClick: () => void;
}) => (
  <StatusChangeModalContent
    adornment={<StatusFailureIcon />}
    title="We were not able to publish the post"
    subtitle={
      <div
        tabIndex={0}
        role="button"
        className="underline text-[16px] text-t2-neutral-grey06"
        onClick={onRetryButtonClick}
      >
        Please try again
      </div>
    }
  />
);
