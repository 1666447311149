type TCollectIconProps = {
  fill?: string;
  className?: string;
};

export const CollectIcon = ({ fill = 'black', className }: TCollectIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4179 5.35399C18.3891 5.22369 18.3168 5.10706 18.213 5.02322C18.1092 4.93938 17.98 4.89331 17.8465 4.89258H12.3531C12.2007 4.89789 12.0562 4.96219 11.9503 5.07192C11.8443 5.18164 11.7851 5.32823 11.7851 5.48077C11.7851 5.63332 11.8443 5.7799 11.9503 5.88963C12.0562 5.99935 12.2007 6.06365 12.3531 6.06896H17.3715L18.6786 11.9509H13.1381C13.0412 11.9508 12.9459 11.9747 12.8605 12.0204C12.7751 12.0662 12.7024 12.1323 12.6488 12.213L12.0381 13.1273H7.96257L7.35188 12.213C7.29832 12.1323 7.2256 12.0662 7.14023 12.0204C7.05485 11.9747 6.95947 11.9508 6.86261 11.9509H1.3221L2.62919 6.06896H7.64758C7.80004 6.06365 7.94447 5.99935 8.05044 5.88963C8.15641 5.7799 8.21564 5.63332 8.21564 5.48077C8.21564 5.32823 8.15641 5.18164 8.05044 5.07192C7.94447 4.96219 7.80004 4.89789 7.64758 4.89258H2.15707C2.02362 4.89331 1.89438 4.93938 1.79056 5.02322C1.68673 5.10706 1.61448 5.22369 1.58566 5.35399L0.0142853 12.4123C0.00499989 12.4537 0 17.245 0 17.245C0 17.4011 0.0620079 17.5508 0.172382 17.6612C0.282757 17.7715 0.432457 17.8335 0.58855 17.8335H19.4114C19.5675 17.8335 19.7172 17.7715 19.8276 17.6612C19.938 17.5508 20 17.4011 20 17.245C20 17.245 19.995 12.4537 19.9857 12.4123L18.4179 5.35399ZM18.8236 16.6572H1.1771V13.128H6.54834L7.1576 14.0423C7.21135 14.1228 7.2841 14.1888 7.36944 14.2345C7.45477 14.2803 7.55006 14.3042 7.64687 14.3044H12.3524C12.4492 14.3042 12.5445 14.2803 12.6298 14.2345C12.7152 14.1888 12.7879 14.1228 12.8417 14.0423L13.4509 13.128H18.8222L18.8236 16.6572Z"
      fill={fill}
      className={className}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.23063 7.41746C7.12046 7.52804 7.05859 7.67778 7.05859 7.83387C7.05859 7.98997 7.12046 8.13971 7.23063 8.25029L9.5834 10.6031C9.69398 10.7132 9.84372 10.7751 9.99982 10.7751C10.1559 10.7751 10.3057 10.7132 10.4162 10.6031L12.769 8.25029C12.8731 8.13858 12.9298 7.99084 12.9271 7.83818C12.9244 7.68552 12.8625 7.53986 12.7546 7.4319C12.6466 7.32394 12.5009 7.26209 12.3483 7.2594C12.1956 7.25671 12.0479 7.31337 11.9362 7.41746L10.5877 8.7667V0.775554C10.5904 0.696587 10.5772 0.617874 10.5489 0.544109C10.5206 0.470343 10.4777 0.403035 10.4228 0.346198C10.3679 0.289361 10.3022 0.244159 10.2294 0.213287C10.1567 0.182414 10.0785 0.166504 9.99946 0.166504C9.92045 0.166504 9.84224 0.182414 9.76951 0.213287C9.69677 0.244159 9.631 0.289361 9.57611 0.346198C9.52122 0.403035 9.47833 0.470343 9.45001 0.544109C9.42169 0.617874 9.40852 0.696587 9.41127 0.775554V8.7667L8.06346 7.41746C7.95288 7.30728 7.80314 7.24542 7.64705 7.24542C7.49095 7.24542 7.34121 7.30728 7.23063 7.41746Z"
      fill={fill}
      className={className}
    />
  </svg>
);
