import { CLOUDINARY_CONFIG } from 'config';
import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { cloudinaryClient } from '../../services/cloudinary/cloudinaryClient';
import { useOnWindowResize } from './hooks';
import { TCloudinaryCropTransformation, TCloudinaryImageShape, TCloudinaryResponsiveImageLoading } from './types';
import { getResizeOptions, round } from './utils';

type TCloudinaryResponsiveImageProps = {
  title?: string;
  image?: TCloudinaryImageShape;
  alt: string;
  className?: string;
  containerClassName?: string;
  aspectRatio?: number;
  loading?: TCloudinaryResponsiveImageLoading;
  stretch?: boolean;
  crop?: TCloudinaryCropTransformation;
  isVisible?: boolean;
};

const MIN_HEIGHT = 1;

const CloudinaryResponsiveImage = ({
  image,
  alt,
  className = '',
  containerClassName = '',
  aspectRatio: customAspectRatio,
  loading = 'lazy',
  crop = 'fill',
  stretch = false,
  isVisible = true,
  ...props
}: TCloudinaryResponsiveImageProps) => {
  const containerRef = useRef<HTMLDivElement>();
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(MIN_HEIGHT);
  const [src, setSrc] = useState('');
  const { width: imageMetaWidth, height: imageMetaHeight } = image?._meta || {};

  const aspectRatio =
    customAspectRatio || (imageMetaWidth && imageMetaHeight && round(imageMetaWidth / imageMetaHeight));

  const handleSetDimensions = useCallback(
    debounce(() => {
      const containerRect = containerRef.current?.getBoundingClientRect();

      if (!containerRect?.width) {
        return;
      }

      if (containerRect.width <= width) {
        return;
      }

      setWidth(Math.ceil(containerRect.width));
      setHeight(Math.ceil(containerRect.height));
    }, 100),
    [width, image],
  );

  useOnWindowResize(handleSetDimensions);

  useEffect(() => {
    handleSetDimensions();
  }, [image, isVisible]);

  useEffect(() => {
    const hasImageId = image?._meta?.public_id || image?.id;

    if (!width || !hasImageId) {
      return;
    }

    const publicId = image?._meta?.public_id || `${CLOUDINARY_CONFIG.folder}/${image?.id}`;
    const cloudinaryImage = cloudinaryClient.image(publicId);
    const resizeOptions = getResizeOptions({ width, height, aspectRatio, crop });

    cloudinaryImage.resize(resizeOptions).quality('auto').format('auto');
    setSrc(cloudinaryImage.toURL());
  }, [crop, image, width, height]);

  return (
    <div
      ref={(ref) => {
        containerRef.current = ref || undefined;
      }}
      className={containerClassName}
      style={{
        maxWidth: !stretch && imageMetaWidth ? imageMetaWidth : 'unset',
        maxHeight: !stretch && imageMetaHeight ? imageMetaHeight : 'unset',
      }}
    >
      {src && (
        <img
          loading={loading}
          className={className}
          src={src}
          alt={alt}
          height={imageMetaHeight || undefined}
          width={imageMetaWidth || undefined}
          style={{
            aspectRatio: aspectRatio || 'unset',
          }}
          {...props}
          data-original-url={image?.url}
        />
      )}
    </div>
  );
};

export default CloudinaryResponsiveImage;
