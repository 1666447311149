export const CONFETTI_ANIMATION_DELAY = 700;

export const enum TerritoryMembershipModalStep {
  details = 'details',
  confirm = 'confirm',
  success = 'success',
}

export const TERRITORY_MEMBERSHIP_STEPS = [
  TerritoryMembershipModalStep.details,
  TerritoryMembershipModalStep.confirm,
  TerritoryMembershipModalStep.success,
] as const;
