import { HTMLProps, ReactNode } from 'react';
import cx from 'classnames';

type DropdownItemProps = HTMLProps<HTMLLIElement> & {
  children: ReactNode;
  className?: string;
};

const DropdownItem: React.FC<DropdownItemProps> = ({ children, onClick, onClickCapture, className }) => (
  <li onClick={onClick} onClickCapture={onClickCapture} className={cx('flex w-full', className)}>
    {children}
  </li>
);

export default DropdownItem;
