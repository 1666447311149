import { useCallback, useState } from 'react';

type TUseAsidePanelProps = {
  initialState?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
};

export const useAsidePanel = ({ initialState = false, onClose, onOpen }: TUseAsidePanelProps = {}) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const closeAsidePanel = useCallback(() => {
    onClose?.();
    setIsOpen(false);
  }, []);

  const openAsidePanel = useCallback(() => {
    onOpen?.();
    setIsOpen(true);
  }, []);

  return { isOpen, closeAsidePanel, openAsidePanel };
};
