import { gql } from '@apollo/client';

export const getUserArticlesData = gql`
  query getUserArticlesData(
    $userId: ID
    $status: String
    $type: ArticleTypeType
    $orderBy: [ArticleOrderByInput!]
    $skip: Int
    $take: Int
  ) {
    user(where: { id: $userId }) {
      id
      articlesCount(where: { status: { equals: $status }, type: { equals: $type }, deactivated: { equals: false } })
      articles(
        skip: $skip
        take: $take
        where: { status: { equals: $status }, type: { equals: $type }, deactivated: { equals: false } }
        orderBy: $orderBy
      ) {
        id
        slug
        shortDescription
        access
        createdAt
        publishedAt
        title
        status
        type
        lastUpdateDate
        readTime
        commentsCount(where: { deactivated: { equals: false } })
        childArticlesCount(where: { status: { equals: "published" }, deactivated: { equals: false } })
        lensPublicationId
        coverImage {
          id
          publicUrl
          publicUrlTransformed
          _meta {
            public_id
            height
            width
          }
        }
        author {
          id
          name
          publicAddress
          username
          profileImage {
            id
            publicUrl
            publicUrlTransformed
            _meta {
              public_id
              height
              width
            }
          }
        }
        totalTimePointsAccumulated
        territory {
          id
          name
          tagColor
        }
        promptSettings {
          id
          startDate
          endDate
          liveStatus
        }
      }
    }
  }
`;
