export const articleCommonData = `
      id
      type
      lastUpdateDate
      shouldStoreOnArweave
      lensPublicationId
      createdAt
      publishedAt
      readTime
      shortDescription
      access
      coverImage {
        id
        publicUrl
        publicUrlTransformed
        _meta {
          public_id
          height
          width
        }
      }
      title
      status
      slug
      content {
        document
      }
      body
      arweaveId
      author {
        id
        name
        publicAddress
        username
        profileImage {
          id
          publicUrl
          publicUrlTransformed
          _meta {
            public_id
            height
            width
          }
        }
      }
      territory {
        id
        name
        slug
        tagColor
        logo {
          id
          publicUrl
          publicUrlTransformed
          _meta {
            public_id
            height
            width
          }   
        }       
      }
      parentArticle {
        id
        type
        title
        slug
        shortDescription
        type
        parentArticle {
          slug
        }
      }
      totalTimePointsAccumulated
      commentsCount(where: { deactivated: { equals: false } })
      territoryPinnedAt
`;
