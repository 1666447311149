import cx from 'classnames';

export const NewFeatureBadge = ({ className }: { className?: string }) => (
  <div
    className={cx(
      'flex items-center justify-center bg-t2-neutral-grey03 text-t2-neutral-grey09 py-2 px-2 rounded-2xl max-h-[30px] truncate text-sm lg:text-xs',
      className,
    )}
  >
    New Feature!
  </div>
);
