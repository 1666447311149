import React from 'react';

interface CrossProps {
  color: string;
  className: string;
}

const Cross = ({ color, className }: CrossProps) => (
  <svg width="20" height="20" className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 5L5 15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 5L15 15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Cross;
