import React from 'react';
import { useNavbarModalContext } from './context/NavbarModalContext';
import ArticleDeleteUnpublishModalComponent from './ArticleDeleteUnpublishModalComponent';
import ShareArticleModalComponent from './ShareArticleModalComponent';
import OnboaridngModalComponent from './OnboaridngModalComponent';

const NavbarModalComponent = () => {
  const { modalType } = useNavbarModalContext();

  switch (modalType) {
    case 'onboarding':
      return <OnboaridngModalComponent />;

    case 'share':
      return <ShareArticleModalComponent />;

    case 'delete':
    case 'unpublish':
      return <ArticleDeleteUnpublishModalComponent />;

    default:
      return null;
  }
};

export default NavbarModalComponent;
