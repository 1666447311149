'use client';

import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useModal } from '../../Modal/useModal';
import { TModalType, TNavbarModalContextProps } from '../types';
import { getAnalyticsModalNameByNavbarModalType } from '../services/getAnalyticsModalNameByModalType';

const NavbarModalContext = createContext<TNavbarModalContextProps>({} as TNavbarModalContextProps);

type TNavbarModalContextProviderProps = {
  children: ReactNode;
};

export const NavbarModalContextProvider = ({ children }: TNavbarModalContextProviderProps) => {
  const [modalType, setModalType] = useState<TModalType | undefined>(undefined);
  const analyticsModalType = modalType && getAnalyticsModalNameByNavbarModalType(modalType);
  const analytics = analyticsModalType && { modalName: analyticsModalType };

  const resetNavbarModalType = () => setModalType(undefined);

  const { openModal, ModalComponent, closeModal, isOpen } = useModal({
    analytics,
    // this callback is required as without it if same modal was opened and closed
    // it would not open again, as the state would not change
    // this navbar modal context should be refactored
    closeModalCallback: resetNavbarModalType,
  });

  const handleOpenArticleShareModal = () => {
    setModalType('share');
  };
  const handleOpenHelpOnboardingModal = () => {
    setModalType('onboarding');
  };

  const handleDeleteClick = () => {
    setModalType('delete');
  };

  const handleUnpublishClick = () => {
    setModalType('unpublish');
  };

  useEffect(() => {
    if (modalType) {
      openModal();
    } else {
      closeModal();
    }
  }, [modalType]);

  const value = useMemo(
    () => ({
      openModal,
      isOpen,
      ModalComponent,
      closeModal: resetNavbarModalType,
      modalType,
      setModalType,
      handleOpenArticleShareModal,
      handleOpenHelpOnboardingModal,
      handleDeleteClick,
      handleUnpublishClick,
    }),
    [isOpen, modalType],
  );

  return <NavbarModalContext.Provider value={value}>{children}</NavbarModalContext.Provider>;
};
export const useNavbarModalContext = () => useContext(NavbarModalContext);
