import React, { FC } from 'react';
import Typo from '../Typography/Typo';
import ShareUrlField from './ShareUrlField';
import { TSharePlatformProps } from './utils/getSharePlatformProps';
import { TSharingPlatform } from './types';

type TShareModalProps = {
  url: string;
  title: string;
  subTitle: string;
  platformlinks: TSharePlatformProps[];
  onCopyToClipboard: () => void;
  onShareButtonClick?: (platform: TSharingPlatform) => void;
};

export const ShareModal: FC<TShareModalProps> = ({
  title,
  subTitle,
  url,
  platformlinks,
  onCopyToClipboard,
  onShareButtonClick,
}) => (
  <div className="flex flex-col justify-center gap-y-8 relative">
    <div className="flex flex-col self-center mb-2">
      <Typo variant="p1" text={title} className="text-center !text-[22px] !leading-[36px] font-semibold" />
      <Typo variant="p2" text={subTitle} className="!text-xl text-gray-700 basis-full" />
    </div>
    <div className="flex flex-col justify-center">
      {url && <ShareUrlField url={url} onCopyButtonClick={onCopyToClipboard} />}
    </div>
    <div className="flex flex-row justify-center gap-x-10">
      {platformlinks.map(({ url, name, platform, icon }) => (
        <a
          key={platform}
          target="_blank"
          href={url}
          rel="noreferrer"
          className="group flex flex-col items-center gap-y-2"
          data-name="x"
          onClick={() => onShareButtonClick?.(platform as TSharingPlatform)}
        >
          <div className="flex h-[46px] w-[46px] items-center justify-center bg-beige-100 group-hover:bg-black-700 rounded-full">
            {icon}
          </div>
          <Typo variant="p2" className="!text-base text-black-700">
            {name}
          </Typo>
        </a>
      ))}
    </div>
  </div>
);
