import { iNotification, Store } from 'react-notifications-component';
import NotificationBanner, { INotificantionProps } from './NotificationBanner';
import 'react-notifications-component/dist/theme.css';

const positionWidthMap = {
  'top-right': 513,
  'top-center': 345,
};

export function showNotification(args: INotificantionProps) {
  const notification: iNotification = {
    content: (
      <NotificationBanner
        displayIcon={args.displayIcon}
        title={args.title || ''}
        message={args.message}
        type={args.type}
      />
    ),
    container: `${args.position || 'top-right'}`,
    insert: 'top',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 10000,
    },
  };

  Store.addNotification({
    ...notification,
    width: positionWidthMap[notification.container],
  });
}
