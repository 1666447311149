import { useEffect } from 'react';

export const useOnArticleStatusToggleEffect = (
  currentStatus: string | undefined,
  latestStatus: string | undefined,
  onStatusChange: () => void,
) => {
  useEffect(() => {
    if (latestStatus && latestStatus !== currentStatus) {
      onStatusChange();
    }
  }, [latestStatus]);
};
