import { ReactNode } from 'react';
import HeyLogoIcon from 'src/components/ShareModal/HeyLogoIcon';
import XLogoIncon from 'src/components/ShareModal/XLogoIncon';
import getShareArticleOnLensUrl from 'utils/getShareArticleOnLensUrl';
import getTwitterShareURL from 'utils/getTwitterShareURL';
import { TSharingPlatform } from '../types';

export type TSharePlatformProps = {
  platform: string;
  name: string;
  url: string;
  icon: ReactNode;
};

export type TGetSharePlatformProps = {
  url: string;
  useTerritoryText?: boolean;
};

export const getSharePlatformProps = ({
  url,
  useTerritoryText = false,
}: TGetSharePlatformProps): TSharePlatformProps[] => [
  {
    platform: 'x' as TSharingPlatform,
    name: 'X',
    url: getTwitterShareURL(url as string, useTerritoryText),
    icon: <XLogoIncon />,
  },
  {
    platform: 'hey' as TSharingPlatform,
    name: 'Hey',
    url: getShareArticleOnLensUrl(url as string, useTerritoryText),
    icon: <HeyLogoIcon />,
  },
];
