import { gql } from '@apollo/client';
import { articleCommonData } from 'src/components/Article/queries/articleCommonData';

export const updateArticleMutation = gql`
  mutation updateArticle($articleId: ID!, $data: ArticleUpdateInput!) {
    updateArticle(where: { id: $articleId }, data: $data) {
      ${articleCommonData}
      articleLink
      shortDescription
      shouldUploadArticleOnLens
      lensPublicationId
    }
  }
`;
