import cx from 'classnames';
import React, { FC } from 'react';

type TIconButtonProps = {
  tag?: 'button' | 'a' | 'div';
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  color?: 'primary' | 'secondary' | 'white';
  disabled?: boolean;
  href?: string;
};

const IconButton: FC<TIconButtonProps> = ({
  tag: Tag = 'button',
  color = 'white',
  onClick,
  children,
  className,
  disabled,
  href,
}) => (
  <Tag
    onClick={onClick}
    className={cx('w-8 h-8 md:w-10 md:h-10', 'flex justify-center items-center', className, {
      'stroke-black-700 hover:stroke-orange-500 bg-white-900 rounded-full active:stroke-orange-500': color === 'white',
      'stroke-white-100 hover:stroke-white-100 bg-black-700 rounded-full hover:bg-orange-500': color === 'primary',
      'stroke-white-100 hover:stroke-white-100 bg-orange-500 rounded-full hover:bg-black-700': color === 'secondary',
      'bg-gray-200 text-gray-400 pointer-events-none': disabled,
    })}
    href={href}
    role={Tag === 'div' ? 'button' : undefined}
  >
    {children}
  </Tag>
);

export default IconButton;
