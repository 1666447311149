import cx from 'classnames';
import { ReactNode } from 'react';
import Typo from '../Typography';
import Cross from './Cross';

export interface INotificantionProps {
  title?: string;
  message?: ReactNode;
  type: 'error' | 'success';
  position?: 'top-right' | 'top-center';
  displayIcon?: boolean;
}
const NotificationBanner = ({ title, message, type, displayIcon = true }: INotificantionProps) => {
  const isSuccessType = type === 'success';
  const isErrorType = type === 'error';
  return (
    <div
      className={cx('w-full px-6 py-4 flex flex-row justify-between rounded-sm ', {
        'bg-green-100': isSuccessType,
        'bg-red-100': isErrorType,
      })}
    >
      <div className="flex flex-row items-center justify-between gap-x-5">
        {displayIcon && (
          <span
            className={cx(
              'self-start relative before:absolute before:w-5 before:h-5 before:top-0 before:-left-3 before:bg-no-repeat before:bg-contain',
              { 'before:bg-notification-success': isSuccessType, 'before:bg-notification-danger': isErrorType },
            )}
          />
        )}
        <div className="flex flex-col">
          <Typo
            variant="p2"
            text={title}
            className={cx('!text-p2-m !font-bold', {
              'text-green-600': isSuccessType,
              'text-red-800': isErrorType,
            })}
          />
          <Typo variant="p2" text={message} className="!text-p2-m text-[#475569]" />
        </div>
      </div>
      <span>
        <Cross
          className="relative w-5 h-5"
          color={cx({
            '#047857': isSuccessType,
            '#B91C1C': isErrorType,
          })}
        />
      </span>
    </div>
  );
};

export default NotificationBanner;
