type TNotificationIconProps = {
  color?: string;
  height?: string;
  width?: string;
  className?: string;
};

export const NotificationSuccessIcon = ({
  className,
  color = '#047857',
  height = '20',
  width = '20',
}: TNotificationIconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11233_21807)">
      <path
        d="M18.3337 9.23355V10.0002C18.3326 11.7972 17.7507 13.5458 16.6748 14.9851C15.5988 16.4244 14.0864 17.4773 12.3631 17.9868C10.6399 18.4963 8.79804 18.4351 7.11238 17.8124C5.42673 17.1896 3.98754 16.0386 3.00946 14.5311C2.03138 13.0236 1.56682 11.2403 1.68506 9.44714C1.80329 7.65402 2.498 5.94715 3.66556 4.58111C4.83312 3.21506 6.41098 2.26303 8.16382 1.867C9.91665 1.47097 11.7505 1.65216 13.392 2.38355"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11233_21807">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
