export const AlertTriangleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M8.17998 3.06824L1.44742 14.3077C1.30861 14.5481 1.23516 14.8207 1.23438 15.0982C1.2336 15.3758 1.30552 15.6488 1.44299 15.8899C1.58045 16.1311 1.77866 16.3321 2.0179 16.4728C2.25714 16.6136 2.52908 16.6893 2.80665 16.6923H16.2718C16.5493 16.6893 16.8213 16.6136 17.0605 16.4728C17.2998 16.3321 17.498 16.1311 17.6354 15.8899C17.7729 15.6488 17.8448 15.3758 17.844 15.0982C17.8433 14.8207 17.7698 14.5481 17.631 14.3077L10.8984 3.06824C10.7567 2.83463 10.5572 2.64149 10.3191 2.50744C10.0811 2.3734 9.81244 2.30298 9.53921 2.30298C9.26599 2.30298 8.99737 2.3734 8.75929 2.50744C8.5212 2.64149 8.32168 2.83463 8.17998 3.06824V3.06824Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.53809 7.15393V10.3334"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.53809 13.5128H9.54603"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
