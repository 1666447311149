import React, { useEffect, useRef, useState } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import confetti from '../../../public/lottie-animations/confetti.json';
import { CONFETTI_ANIMATION_DELAY } from './constants';

const ConfettiAnimation = () => {
  const [isVisible, setIsVisible] = useState(true);
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    setTimeout(() => {
      lottieRef.current?.play();
    }, CONFETTI_ANIMATION_DELAY);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Lottie
        lottieRef={lottieRef}
        className="absolute top-10 left-1/2 -translate-x-1/2 w-40 h-44 md:w-44 lg:w-48 lg:h-48 rotate-[20deg] scale-[2.5]"
        animationData={confetti}
        loop={false}
        onComplete={() => {
          lottieRef.current?.destroy();
          setIsVisible(false);
        }}
      />
      <Lottie
        lottieRef={lottieRef}
        className="absolute top-10 left-1/2 -translate-x-1/2 w-40 h-44 md:w-44 lg:w-48 lg:h-48 -rotate-[20deg] scale-[2.5]"
        animationData={confetti}
        loop={false}
        onComplete={() => {
          lottieRef.current?.destroy();
        }}
      />
    </>
  );
};

export default ConfettiAnimation;
