import React from 'react';
import { TGetModalComponent } from 'src/components/Modal/useModal';
import { ArticleEditorStatusChangeLoadingModalContent } from './ArticleEditorStatusChangeLoadingModalContent';
import { ArticleEditorStatusChangeSuccessModalContent } from './ArticleEditorStatusChangeSuccessModalContent';
import { ArticleEditorStatusChangeFailureModalContent } from './ArticleEditorStatusChangeFailureModalContent';

type TArticleEditorStatusChangeModalProps = {
  ModalComponent: TGetModalComponent;
  status: 'loading' | 'failure' | 'success';
  articleId: string;
  articleSlug: string;
  handleSubmitArticleEditor: () => void;
};

export const ArticleEditorStatusChangeModal = ({
  ModalComponent,
  articleId,
  articleSlug,
  status,
  handleSubmitArticleEditor,
}: TArticleEditorStatusChangeModalProps) => (
  <ModalComponent
    className="z-[60]"
    title={status === 'success' ? 'Post successfully published' : undefined}
    subtitle={status === 'success' ? 'Here are few things you can do next' : undefined}
  >
    {status === 'loading' && <ArticleEditorStatusChangeLoadingModalContent />}
    {status === 'failure' && (
      <ArticleEditorStatusChangeFailureModalContent onRetryButtonClick={handleSubmitArticleEditor} />
    )}
    {status === 'success' && (
      <ArticleEditorStatusChangeSuccessModalContent articleId={articleId} articleSlug={articleSlug} />
    )}
  </ModalComponent>
);
