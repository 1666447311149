import React from 'react';
import { StatusChangeModalContent } from 'src/components/StatusChangeModal/StatusChangeModalContent';
import { StatusChangeModalLoader } from '../StatusChangeModal/StatusChangeModalLoader';

export const ArticleEditorStatusChangeLoadingModalContent = () => (
  <StatusChangeModalContent
    adornment={<StatusChangeModalLoader />}
    title="Your post is being published"
    subtitle="This might take a few seconds"
  />
);
