import cx from 'classnames';
import Button from '../Button/Button';
import { transitionAnimationClassNames } from '../../../utils/transitionAnimationClassNames';

export type TShareUrlFieldProps = {
  url: string;
  onCopyButtonClick: () => void;
};

const ShareUrlField: React.FC<TShareUrlFieldProps> = ({ onCopyButtonClick, url }) => (
  <div className="flex p-1 pl-6 pr-[5px] gap-x-2 items-center justify-between border border-gray-400 rounded-[36px] focus:after:!bg-transparent">
    <div className="relative !text-base bg-white-900 whitespace-nowrap overflow-x-auto no-scrollbar">{url}</div>
    <span className="relative after:z-10 after:absolute after:-top-[12px] after:right-0 after:w-10 after:h-6 after:bg-input-gradient-right" />

    <Button
      variant="primary"
      title="Copy"
      className={cx(
        '!py-3 !px-[25px] !font-normal !text-base text-white-900 bg-black-700 !rounded-[36px] cursor-pointer hover:bg-orange-500',
        transitionAnimationClassNames,
      )}
      onClick={onCopyButtonClick}
    />
  </div>
);
export default ShareUrlField;
