import { gql } from '@apollo/client';
import { articleCommonData } from '../../Article/queries/articleCommonData';

const getArticleDataForEditorByArticleIdQuery = gql`
  query getArticleDataForEditorByArticleId($articleId: ID) {
    article(where: { id: $articleId }) {
      ${articleCommonData}
      shouldUploadArticleOnLens
      lensPublicationId
    }
  }
`;

export default getArticleDataForEditorByArticleIdQuery;
