import { OutputData } from '@editorjs/editorjs';

const sanitizeArticleBlocks = (body: OutputData) => {
  const sanitizedBlocks = body?.blocks?.filter(
    ({ data: { code, caption, text, content, items }, type }) =>
      !(
        (type === 'code' && !code) ||
        (type === 'quote' && !caption && !text) ||
        (type === 'table' && content?.length === 0) ||
        (type === 'list' && items && !items[0]?.content)
      ),
  );
  return {
    ...body,
    blocks: sanitizedBlocks,
  };
};

export default sanitizeArticleBlocks;
