import React from 'react';
import Image from 'next/image';
import { getArticlePageUrl } from 'src/services/pagesUrl/getArticlePageUrl';
import { getArticleSettingsPageUrl } from 'src/services/pagesUrl/getArticleSettingsPageUrl';
import { APP_URL } from 'config';
import ConfettiAnimation from '../TerritoryMembershipModal/ConfettiAnimation';
import ArrowRight from '../Icons/arrow-right';
import { ListButton } from '../ListButton/ListButton';
import { CollectIcon } from '../Icons/CollectIcon';
import { ArticleSettingsSubpage } from '../ArticleSettings/constants';
import { LensIcon } from '../Icons/LensIcon';

export const ArticleEditorStatusChangeSuccessModalContent = ({
  articleId,
  articleSlug,
}: {
  articleId: string;
  articleSlug: string;
}) => {
  const rightAdornment = (
    <div className="w-[18px] h-[18px] flex justify-center items-center">
      <ArrowRight height="16" width="16" />
    </div>
  );

  const buttons = [
    {
      leftAdornment: (
        <div className="w-10 h-10 rounded-full bg-t2-primary-brandColor flex items-center justify-center">
          <Image width={15} height={18} src="/images/t2-logo-dark.svg" alt="t2 logo" />
        </div>
      ),
      rightAdornment,
      title: 'View and share your published post',
      subtitle: `${APP_URL}${getArticlePageUrl(articleSlug)}`,
      href: getArticlePageUrl(articleSlug),
    },
    {
      leftAdornment: (
        <div className="w-10 h-10 rounded-full bg-t2-warning-lightYellow flex items-center justify-center">
          <CollectIcon className="fill-t2-warning-darkYellow" />
        </div>
      ),
      rightAdornment,
      title: 'Create a collectible',
      subtitle: 'Create a digital collectible for readers to support you',
      href: getArticleSettingsPageUrl(articleId, ArticleSettingsSubpage.collectibles),
    },
    {
      leftAdornment: (
        <div className="w-10 h-10 rounded-full bg-[#D8CDF0] flex items-center justify-center">
          <LensIcon fill="#9747FF" />
        </div>
      ),
      rightAdornment,
      title: 'Create a Lens Post',
      subtitle: 'Amplify your post to reach broader audience',
      href: getArticleSettingsPageUrl(articleId, ArticleSettingsSubpage.lens),
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <div className="-z-10">
        <ConfettiAnimation />
      </div>
      {buttons.map((props) => (
        <ListButton key={props.href} {...props} />
      ))}
    </div>
  );
};
