import { useRouter } from 'next/compat/router';
import { APP_URL } from '../../../config';
import { showNotification } from '../NotificationBanner/showNotification';
import { getArticlePageUrl } from '../../services/pagesUrl/getArticlePageUrl';
import { getPromptPageUrl } from '../../services/pagesUrl/getPromptPageUrl';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { ShareModal } from './ShareModal';
import { TSharePlatformProps, getSharePlatformProps } from './utils/getSharePlatformProps';
import { TSharingPlatform } from './types';

export type TShareArticleModalContainerProps = {
  articleType?: 'ARTICLE' | 'PROMPT';
};

export const ShareArticleModalContainer: React.FC<TShareArticleModalContainerProps> = ({ articleType }) => {
  const { sendEvent } = useAnalytics();
  const router = useRouter();

  const articleUrl = `${APP_URL}${
    articleType === 'PROMPT'
      ? getPromptPageUrl(router?.query?.promptSlug as string)
      : getArticlePageUrl(router?.query?.articleSlug as string)
  }`;
  const shareOnExternalPlatformLinks = getSharePlatformProps({ url: articleUrl });
  const title = articleType === 'ARTICLE' ? 'Share this post' : 'Share this prompt';

  const copyToClipboard = () => {
    sendEvent('post_share_url_button_click');
    navigator?.clipboard?.writeText(articleUrl as string);
    showNotification({
      title: 'Copied',
      message: 'The url has been copied to the clipboard',
      type: 'success',
    });
  };

  const onShareButtonClick = (platform: TSharingPlatform) => {
    sendEvent('post_share_on_platform_button_click', { platform });
  };

  return (
    <ShareModal
      title={title}
      subTitle="Let your friends know what you are reading."
      url={articleUrl}
      onCopyToClipboard={copyToClipboard}
      onShareButtonClick={onShareButtonClick}
      platformlinks={shareOnExternalPlatformLinks as TSharePlatformProps[]}
    />
  );
};
