import { BlockMutationEvent } from '@editorjs/editorjs/types/events/block';

export const hideConversionToolbarOnInlineToolbarClick = (event: BlockMutationEvent | BlockMutationEvent[]) => {
  if (event?.type === 'block-changed') {
    const inlineToolInput = document.getElementsByClassName('ce-inline-tool-input--showed')[0];

    if (inlineToolInput) {
      const keydownHandler = (event) => {
        if (event.target.value.length === 0) {
          const conversionToolbar = document.getElementsByClassName('ce-conversion-toolbar--showed')[0];

          if (conversionToolbar) {
            const inlineToolbarDropdown = document.getElementsByClassName(
              'ce-inline-toolbar__dropdown',
            )[0] as HTMLElement;
            inlineToolbarDropdown?.click();
            inlineToolInput.removeEventListener('keydown', keydownHandler);
          }
        }
      };

      inlineToolInput.addEventListener('keydown', keydownHandler);
    }
  }
};
