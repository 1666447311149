import { FC, ReactNode } from 'react';
import cx from 'classnames';
import Spinner from '../Spinner/Spinner';
import { transitionAnimationClassNames } from '../../../utils/transitionAnimationClassNames';

type TConfirmationModalButtonProps = {
  isError?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  isConfirm?: boolean;
  onClick: () => void;
};

export const ConfirmationModalButton: FC<TConfirmationModalButtonProps> = ({
  isError,
  isLoading = false,
  isConfirm = false,
  children,
  onClick,
}) => (
  <button
    disabled={isError || isLoading}
    onClick={onClick}
    className={cx(
      'relative basis-1/3 sm:basis-1/4 py-2 md:py-3 px-4 shadow-staticShadow text-sm md:text-base font-secondary !rounded-[26px] capitalize whitespace-nowrap text-center',
      transitionAnimationClassNames,
      {
        ' bg-white-900 text-black-700 border border-black-700 hover:bg-black-700 hover:text-white-900': !isConfirm,
        'bg-orange-500 text-white-900 hover:bg-orange-900': isConfirm,
      },
    )}
  >
    {isLoading && (
      <div className="absolute flex left-1/2 -translate-x-1/2">
        {' '}
        <Spinner size={9} color="white" />
      </div>
    )}
    <div
      className={cx({
        invisible: isLoading,
        visible: !isLoading,
      })}
    >
      {children}
    </div>
  </button>
);
