import { useEffect, useRef } from 'react';

type TClickAwayHandler = VoidFunction;

export const useClickAway = <T extends HTMLElement>(handler: TClickAwayHandler) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutsideOfDropdown = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        handler();
      }
    };

    window.addEventListener('click', handleClickOutsideOfDropdown);

    return () => {
      window.removeEventListener('click', handleClickOutsideOfDropdown);
    };
  });

  return ref;
};
