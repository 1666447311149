import { FC } from 'react';
import cx from 'classnames';

type TDropdownSeparatorProps = {
  className?: string;
};

const DropdownSeparator: FC<TDropdownSeparatorProps> = ({ className }) => (
  <hr className={cx('h-px border-gray-200 mx-2 w-full', className)} />
);

export default DropdownSeparator;
