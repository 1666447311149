import cx from 'classnames';
import React from 'react';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/compat/router';
import { Article as TArticle } from 't2-graphql-types';
import ConfirmationModalContent from '../ConfirmationModal/ConfirmationModalContent';
import { useNavbarModalContext } from './context/NavbarModalContext';
import { useArticleEditorContext } from '../ArticleEditor/contexts/ArticleEditorContext';
import { useAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { deleteArticle as deleteArticleMutation } from '../ArticleEditor/mutations/deleteArticle';
import { getUserArticlesData } from '../UserBookshelfSection/queries/getUserArticlesData';
import { getUserPageUrl } from '../../services/pagesUrl/getUserPageUrl';
import ConfirmationModalActions from '../ConfirmationModal/ConfirmationModalActions';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';

const HEADER_TEXT = {
  delete: 'Delete post',
  unpublish: 'Unpublish post',
};
const BODY_TEXT = {
  delete: (
    <>
      Deleting the post will remove it completely. There will be no changes to any t.p. related to this post.
      <br /> This action cannot be undone.
    </>
  ),
  unpublish: (
    <>Unpublishing will bring the post back to draft. Any reading activities related to the post will be saved.</>
  ),
};

const ArticleDeleteUnpublishModalComponent = () => {
  const router = useRouter();
  const { ModalComponent, modalType, closeModal, isOpen } = useNavbarModalContext();
  const { status, articleId, onToggleStatus: toggleArticleStatus } = useArticleEditorContext();
  const { sendEvent } = useAnalytics();

  const { data: authenticatedUserData, authenticatedUserId } = useAuthenticatedUser();
  const authenticateUserUsername = authenticatedUserData?.user?.username;

  const [deleteArticle] = useMutation<Partial<TArticle>>(deleteArticleMutation);

  const handleDelete = async () => {
    try {
      await deleteArticle({
        variables: { articleId },
        refetchQueries: [
          {
            query: getUserArticlesData,
            variables: {
              userId: authenticatedUserId,
              status,
              type: 'ARTICLE',
              orderBy: status === 'draft' ? { createdAt: 'desc' } : { publishedAt: 'desc' },
            },
          },
        ],
      });

      sendEvent('native_editor_delete_post_success', { post_id: articleId });
      router?.push(getUserPageUrl(authenticateUserUsername as string));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  const handleClickOnConfirm = () => {
    if (modalType === 'unpublish') {
      toggleArticleStatus();
      sendEvent('native_editor_unpublish_post_success', { post_id: articleId });
    } else {
      handleDelete();
    }
    closeModal();
  };

  return (
    <div
      className={cx({
        'sm:w-screen sm:h-screen sm:absolute sm:left-[calc(500px-100vw)] sm:top-0 md:inset-0': isOpen,
      })}
    >
      <ModalComponent
        // @ts-ignore
        title={HEADER_TEXT[modalType]}
        footer={
          <ConfirmationModalActions handleCancel={closeModal} handleConfirm={handleClickOnConfirm} isLoading={false} />
        }
      >
        {/* @ts-ignore */}
        <ConfirmationModalContent body={BODY_TEXT[modalType]} />
      </ModalComponent>
    </div>
  );
};
export default ArticleDeleteUnpublishModalComponent;
