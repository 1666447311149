import React, { ReactNode } from 'react';
import cx from 'classnames';

type TListButtonProps = {
  href: string;
  leftAdornment: ReactNode;
  rightAdornment: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  variant?: 'success' | 'primary';
};

export const ListButton = ({
  href,
  leftAdornment,
  rightAdornment,
  title,
  subtitle,
  variant = 'primary',
}: TListButtonProps) => (
  <a href={href} className="p-2 border border-t2-neutral-grey03 hover:border-t2-primary-brandColor rounded-[8px]">
    <div className="flex gap-2 items-center">
      <div className="flex shrink-0">{leftAdornment}</div>
      <div className="flex flex-col flex-grow overflow-hidden">
        <div
          className={cx('text-p2-m font-semibold overflow-hidden overflow-ellipsis text-nowrap', {
            'text-t2-neutral-grey09': variant === 'primary',
            'text-t2-success-neonGreen': variant === 'success',
          })}
        >
          {title}
        </div>
        <div className="text-p2-m text-t2-neutral-grey05 overflow-hidden overflow-ellipsis">{subtitle}</div>
      </div>
      {rightAdornment}
    </div>
  </a>
);
