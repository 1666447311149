import { inTextEllipsisCenter } from 't2-keystone/utils/text-ellipsis-center';
import { User } from 't2-graphql-types';

const getUserDisplayName = ({ name, username }: Pick<User, 'name' | 'username'> = {}, initialChars = 25): string => {
  const displayName = name?.trim() || username?.trim() || '';
  if (displayName.length > initialChars) {
    return inTextEllipsisCenter(displayName, 5, 5);
  }

  return displayName;
};

export default getUserDisplayName;
