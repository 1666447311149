import { fill, limitFill } from '@cloudinary/url-gen/actions/resize';
import { TCloudinaryCropTransformation } from './types';
import { IMAGE_WIDTH_TRANSFORMATIONS } from './constants';

const getClosestImageSize = (width: number) => {
  const largestWidth = IMAGE_WIDTH_TRANSFORMATIONS[IMAGE_WIDTH_TRANSFORMATIONS.length - 1];
  return IMAGE_WIDTH_TRANSFORMATIONS.find((prop) => prop > width) || largestWidth;
};

export const round = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;

const getResizeMethod = (crop: TCloudinaryCropTransformation) => {
  switch (crop) {
    case 'fill':
      return fill;
    case 'limit':
      return limitFill;
    default:
      return fill;
  }
};

export const getResizeOptions = ({
  crop,
  width,
  height,
  aspectRatio,
}: {
  crop: TCloudinaryCropTransformation;
  width: number;
  height: number;
  aspectRatio?: number | null;
}) => {
  const optimalWidth = getClosestImageSize(width);
  const optimalHeight = Math.max(Math.ceil(optimalWidth / (aspectRatio || 1)), height);
  const resizeOptions = getResizeMethod(crop)().width(optimalWidth);

  if (optimalHeight) {
    resizeOptions.height(optimalHeight);
  }

  if (aspectRatio) {
    resizeOptions.aspectRatio(aspectRatio);
  }

  return resizeOptions;
};
