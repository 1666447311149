import { useEffect } from 'react';
import { usePrivy } from 't2-keystone/packages/privyAuthentication/src/hooks/usePrivy';

// Refresh the Privy token every 5 minutes
const PRIVY_TOKEN_REFRESH_INTERVAL = 1000 * 60 * 5;

export const useRefreshPrivyTokenEffect = () => {
  const { login, logout, ready, getAccessToken, user, authenticated } = usePrivy();

  const onAccessTokenRefresh = async (token: string | null) => {
    if (!token) {
      await logout();
      await login();
    }
  };

  useEffect(() => {
    if (!ready || !authenticated) {
      return;
    }

    const interval = setInterval(() => {
      getAccessToken().then(onAccessTokenRefresh);
    }, PRIVY_TOKEN_REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [ready, authenticated, user]);
};
