import { OutputData, OutputBlockData } from '@editorjs/editorjs';
import { useRef, useEffect } from 'react';
import differenceWith from 'lodash/differenceWith';
import { ImageBlockToolData, ImageOutputBlockData } from '@editorjs/image';
import { useMutation } from '@apollo/client';
import { TArticleEditData } from '../types';
import { removeImageFromArticleBody } from '../mutations/removeImageFromArticleBody';

type TUseOnArticleImagesChangeEffectProps = {
  initialBody: TArticleEditData['body'];
  currentBody: OutputData;
  articleId: string;
  onImagesChange: () => void;
};

export const useOnArticleImagesChangeEffect = ({
  initialBody,
  currentBody,
  articleId,
  onImagesChange,
}: TUseOnArticleImagesChangeEffectProps) => {
  const prevArticleBodyRef = useRef<OutputData | null>(initialBody);

  const [removeImageRequest] = useMutation(removeImageFromArticleBody);
  const handleImageRemove = async (data: ImageBlockToolData) => {
    const publicId = data.file._meta.public_id;
    await removeImageRequest({ variables: { publicId, articleId } });
  };

  const imageBlockPredicate = (block: OutputBlockData) => block.type === 'image' && !!block.data.file?.url;

  const imageBlockUrlComparator = (a: ImageOutputBlockData, b: ImageOutputBlockData) =>
    a.data.file.url === b.data.file.url;

  useEffect(() => {
    const prevImages = (prevArticleBodyRef.current?.blocks?.filter(imageBlockPredicate) ||
      []) as ImageOutputBlockData[];
    const currentImages = (currentBody?.blocks?.filter(imageBlockPredicate) || []) as ImageOutputBlockData[];
    const removedImagesDiff = differenceWith(prevImages, currentImages, imageBlockUrlComparator);
    const addedImagesDiff = differenceWith(currentImages, prevImages, imageBlockUrlComparator);
    const didImagesChange = removedImagesDiff.length > 0 || addedImagesDiff.length > 0;
    const isMostRecentBodyUpdate =
      currentBody.time && prevArticleBodyRef.current?.time && currentBody.time > prevArticleBodyRef.current?.time;
    const shouldUpdate = currentBody && isMostRecentBodyUpdate && didImagesChange;

    prevArticleBodyRef.current = currentBody;

    if (removedImagesDiff.length > 0) {
      removedImagesDiff.map((imageBlock) => imageBlock.data).forEach(handleImageRemove);
    }

    if (shouldUpdate) {
      onImagesChange();
    }
  }, [currentBody]);
};
