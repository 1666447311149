import { useArticleEditorContext } from '../ArticleEditor/contexts/ArticleEditorContext';
import { NotificationSuccessIcon } from '../NotificationSuccessIcon/NotificationSuccessIcon';

export const ArticleSettingsGeneralSavedIcon = () => {
  const { dirtyFields } = useArticleEditorContext();

  const hasChanged = dirtyFields.territory || dirtyFields.shouldStoreOnArewave;

  return hasChanged ? null : <NotificationSuccessIcon className="w-5 h-5" color="currentColor" />;
};
