export const BookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M17.4868 2.38464H12.7176C11.8743 2.38464 11.0656 2.71962 10.4693 3.31589C9.87307 3.91216 9.53809 4.72088 9.53809 5.56413V16.6923C9.53809 16.0599 9.78932 15.4534 10.2365 15.0062C10.6837 14.559 11.2903 14.3077 11.9227 14.3077H17.4868V2.38464Z"
      stroke="currentColor"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.58984 2.38464H6.35907C7.20233 2.38464 8.01104 2.71962 8.60731 3.31589C9.20358 3.91216 9.53856 4.72088 9.53856 5.56413V16.6923C9.53856 16.0599 9.28733 15.4534 8.84012 15.0062C8.39292 14.559 7.78639 14.3077 7.15395 14.3077H1.58984V2.38464Z"
      stroke="currentColor"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
