import cx from 'classnames';
import React from 'react';
import { NAVBAR_ID } from './constants';
import NavbarModalComponent from '../NavbarModalComponent/NavbarModalComponent';

interface NavbarWrapperProps {
  children?: React.ReactNode;
  themeVariant?: 'light' | 'beige';
  translateYValue?: number;
}

const themeVariantClass = {
  light: 'bg-white-100',
  beige: 'bg-beige-100',
};

const NavbarWrapper: React.FC<NavbarWrapperProps> = ({ children, themeVariant = 'light', translateYValue }) => (
  <>
    <div
      id={NAVBAR_ID}
      className={cx('fixed w-full top-0 z-50 duration-200 transition', themeVariantClass[themeVariant])}
      style={{ transform: `translateY(${translateYValue}px)` }}
    >
      {children}
    </div>
    <NavbarModalComponent />
  </>
);

export default NavbarWrapper;
