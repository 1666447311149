import { useRef, useState } from 'react';
import {
  autoUpdate,
  useFloating,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  safePolygon,
  arrow,
} from '@floating-ui/react';
import { Props as TFloatingUIHoverProps } from '@floating-ui/react/src/hooks/useHover';
import { TToolTipDirection } from '../types';

export const useTooltip = (direction: TToolTipDirection, floatingUIHover: TFloatingUIHoverProps = {}) => {
  const [active, setActive] = useState(false);
  const arrowRef = useRef(null);

  const { x, y, refs, strategy, context } = useFloating({
    open: active,
    onOpenChange: setActive,
    placement: direction,
    whileElementsMounted: autoUpdate,
    middleware: [offset(10), flip({ fallbackAxisSideDirection: 'end' }), shift(), arrow({ element: arrowRef })],
  });

  const hover = useHover(context, {
    move: false,
    handleClose: safePolygon(),
    ...floatingUIHover,
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  return {
    active,
    x,
    y,
    refs,
    strategy,
    arrowRef,
    context,
    getReferenceProps,
    getFloatingProps,
  };
};
