import React, { ReactNode } from 'react';
import Typo from '../Typography';

export const StatusChangeModalContent = ({
  adornment,
  title,
  subtitle,
}: {
  adornment: ReactNode;
  title: string;
  subtitle: ReactNode;
}) => (
  <div className="flex flex-col justify-center items-center gap-14 pt-10 pb-14">
    <div className="w-[80px]">{adornment}</div>
    <div className="flex flex-col justify-center items-center gap-3">
      <Typo className="text-xl font-semibold">{title}</Typo>
      <Typo className="text-base text-center">{subtitle}</Typo>
    </div>
  </div>
);
