import cx from 'classnames';
import { CSSProperties } from 'react';

export interface ContainerProp {
  children: React.ReactNode;
  className?: string;
  isInsideContainer?: boolean;
  style?: CSSProperties;
}
const Container = ({ children, className = '', isInsideContainer, style }: ContainerProp) => (
  <div
    data-testid="Container"
    className={cx('max-w-[1440px] mx-auto grid grid-cols-4 lg:grid-cols-12 gap-x-2 lg:gap-x-5', {
      'px-0 lg:px-0': isInsideContainer,
      'px-4 lg:px-8': !isInsideContainer,
      [className]: className,
    })}
    style={style}
  >
    {children}
  </div>
);

export default Container;
