import { FC, ReactNode } from 'react';
import Typo from '../Typography';

type TConfirmationModalContentProps = {
  body: string | ReactNode;
};

const ConfirmationModalContent: FC<TConfirmationModalContentProps> = ({ body }) => (
  <div>
    <Typo variant="p2" className="!text-[16px] text-gray-400 pb-6" text={body} />
  </div>
);
export default ConfirmationModalContent;
